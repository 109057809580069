<template>
    <div class="col-12">
        <div class="card">
            <Loader v-model="loading"/>
            <HelperDialog v-model="labHelper" header="Buscar Direccion" :headers="labHeaders" :rows="labs" @selected="selectLab" />
            <Panel header="Libre a Bordo">
                <BasicFormToolbar v-if="!view" @new="openNew" @save="save" @refresh="refresh" :actions="['new','save','refresh']" />
                <Panel header="Datos generales">
                    <div class="p-fluid formgrid grid">
                        <div class="field col-12">
                            <FormInputText wrapperClass="field col-3" label="Folio" @search="(labHelper.visible= true)"  :search="true"/>
                        </div>
                        <FormInputText wrapperClass="field col-4" label="Nombre" :valid="validate.validations.name" v-model="entity.name"/>
                        <FormInputText wrapperClass="field col-4" label="Nombre Corto" :valid="validate.validations.key_name" v-model="entity.key_name"/>
                        <FormInputText wrapperClass="field col-4" label="Direccion" :valid="validate.validations.address" v-model="entity.address"/>
                    </div>
                </Panel>
            </Panel>
        </div>
    </div>
</template>

<script>
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue';
import Loader from '../../../components/general/Loader.vue';
import FormInputText from '../../../components/general/FormInputText.vue';
import {LAB} from "../../../models/compras/LAB";
import Session from "../../../mixins/sessionMixin";
import HelperDialog from '../../../components/general/HelperDialog.vue';


import {
    HeaderGrid,
    Rule,
    validate,
    Toast,
    ErrorToast,
    fillObject
} from "../../../utilities/General";

export default{
    mixins: [Session],
    props: {
        modal: null,
    },
    data(){
        return{
            entity: new LAB(),
            entities: [],
            labs:[],
            loading: false,
            labHelper:{
                visible:false
            },
            rules: [
                new Rule({ name: "name" }),
                new Rule({ name: "key_name" }),
                new Rule({ name: "address" }),
            ],
            validate: {
                valid: false,
                validations: {
                    name: null,
                    key_name: null,
                    address: null,
                },
            },
            labHeaders:[
              new HeaderGrid('Nombre','name'),
              new HeaderGrid('Nombre corto','key_name'),        
              new HeaderGrid('Direccion','address')
            ]
        }
    },
    components:{BasicFormToolbar,Loader,FormInputText,HelperDialog},
    created() {
        this.entity = new LAB(this.session);
    },
    async mounted() {
        await this.refresh();
    },
    methods:{
        selectLab(payload){
            console.log('payload', payload),
            this.entity = fillObject(this.entity, payload);
        },
        openNew() {
            this.entity = new LAB(this.session);
        },
        async refresh() {
          this.loading = true;
          try {
            if (!this.modal) 
              this.entities = await this.entity.all();
              this.labs = await new LAB(this.session).all();
              if (this.id) {
              this.entity.id = this.id;
              let entity = await this.entity.retrieve();
              this.entity = fillObject(this.entity, entity);
            }
          } catch (error) {
            this.$toast.add(new ErrorToast(error));
          } finally {
            this.loading = false;
          }
        },
        async save() {
            try {
                //* Validacion de form
                this.loading = true;
                this.validate = validate(this.entity, this.rules);
                if (!this.validate.valid) {
                    throw "Favor de validar los campos";
                }
                //* Si el id es != 0 entonces actualizamos, si no, guardamos
                if (this.entity.id && this.entity.id > 0) {
                    //* Actualizamos
                    let entity = await this.entity.update();
                    this.$toast.add({
                        severity: "success",
                        summary: "Actualizar",
                        detail: "Informacion actualizada con exito",
                        life: 3000,
                    });
                    this.$emit('update', entity);
                    //* Modificamos el listado pah
                    let index = this.entities.findIndex((x) => x.id == this.entity.id);
                    this.entities[index] = entity;
                } else {
                    this.entity.status = this.status;
                    let entity = await this.entity.save();
                    //* Agregamos un dato extra
                    this.$emit('save', entity);
                    this.entities.push(entity);
                    this.$toast.add(
                        new Toast({
                            summary: "Creacion",
                            detail: "Informacion guardada con exito",
                        })
                    );
                }
                this.entity = new LAB(this.session);
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>


<style>
</style>